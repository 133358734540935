import { Container } from "@mui/material";
import React from "react";
import qrIconin from "../../imgs/qrcodeinvalide.png";
import { useState, useEffect } from "react";
import { Box, Button, Typography, Avatar} from "@mui/material";
import Api from "../../api/Api";
import MetaTags from 'react-meta-tags';
import { Urls } from "../../api/Urls";
import bdigitall from "../../imgs/BDIGITALL.svg";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FillProfile from "../admin/FillProfile";
import FillProfileProsumer from '../admin/FillProfileProsumer'
import AbonneeApercueForProsumer from "./AbonneeApercuForProsumer";
import AbonneeApercueDefault from "./AbonneeApercuDefault";
import API from "../../api/Api_1_3";
import SelectPlan from "../admin/SelectPlan";
import FillProfileProPlus from "../admin/FillProfileProPlus";


function AbonneeApercue() {
  //const { user } = useAuthContext();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [role,setRole] = useState(null)
  const [prosumer, setProsumer] = useState('')
  const [data,setData] = useState()
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false);
  const [errornotfound, setErroNotFound] = useState(false);  

  //on page load (load user details)
  useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setData(response.data.user);
        if(response?.data?.user?.role !== null ){
          setIsProfile(true)
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 405) {
          setError(true);
          setLoading(false);
        }
        if (error.response.status === 404) {
          setErroNotFound(true);
          setLoading(false);
        }
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
      });
  }, []);

  //open modal
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const showForm = () =>{
    setOpenForm(!openForm)
  }

  const selectRole = (data) =>{
    if(!data){
      setRole('CUSTOMER')
    }
    else if(data?.user_type === 'PROSUMER') {
      setRole('PROSUMER')
      setProsumer(data)
    }
    else if(data?.user_type === 'PRO_PLUS_ADMIN') {
      setRole('PRO_PLUS_ADMIN')
      setProsumer(data)
    }
    else if (data?.user_type == 'PRO_PLUS_COLLABORATOR'){
      setRole('PRO_PLUS_COLLABORATOR')
      setProsumer(data)
    }
  }

  
  const [isError,setIsError] = useState(false)


  const [isCollapsed, setIsCollapsed] = useState(true);


  const [isProfile, setIsProfile] = useState(false);


  return (
    <>
      { 
        // Check for profile first
        (loading === false) && (
          <>
              <MetaTags>
                <title>{data?.first_name ? (data?.first_name + ' ' + data?.last_name) : 'BdigitAll'} </title>
                <meta name="description" content={`${data?.function}`} />
                <meta property="og:title" content={`${data?.first_name} ${data?.last_name}`} />
                <meta property="og:image" content={ data?.url_profile_picture ?? <Avatar/>} />
              </MetaTags>
            { 
              (error === false && errornotfound === false) ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: 'center',
                  justifyContent: 'center',
                }}
              >
                <Container
                  sx={{
                    minHeight: "100%",
                    display: "flex",
                    alignItems: "center",
                    alignSelf: 'center',
                    justifyContent: 'center',
                    padding:{
                      xs: 0,
                      md: 0,
                      sm: 0,
                      lg: 0,
                    },
                    margin:{
                      xs: 0,
                      md: 0,
                      sm: 0,
                      lg: 0,
                    },
                  }}
                maxWidth="md"
                >
                  { 
                    (data?.role === 'PROSUMER' && isProfile === true)
                    ? <AbonneeApercueForProsumer/>
                    : (data?.role === 'COLLABORATOR' || data?.role === 'CLIENT_ADMIN' || data?.role === 'PRO_PLUS_ADMIN' || data?.role === 'CUSTOMER' || data?.role === 'PRO_PLUS_COLLABORATOR' && isProfile === true)
                    ? <AbonneeApercueDefault/>
                    : null
                  }
                </Container>
              </Box>
              ) : (
                <Box>
                  <Container
                    sx={{
                      minHeight: "100vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        md: "center",
                        sm: "center",
                        lg: "center",
                      },
                    }}
                    maxWidth="sm"
                  >
                    <img
                      style={{ margin: "1rem 0rem" }}
                      src={bdigitall}
                      alt="bdigitall logo"
                    />
                    <Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          alignItems: "center",
                          backgroundColor: "#F9F6FF",
                          borderRadius: "2rem",
                          padding: "3rem",
                        }}
                      >
                        <Typography
                          marginBottom="1rem"
                          sx={{ textAlign: "center" }}
                        >
                          {error ? "Carte Inactive" : "Aucun utilisateur trouvé"}
                        </Typography>
                        <Avatar
                          sx={{
                            position: "relative",
                            borderRadius: "20px",
                            width: "12rem",
                            height: "11rem",
                            border: "1px solid #C9C5D2",
                            marginBottom: "1rem",
                          }}
                          variant="rounded"
                        >
                          {qrIconin ? (
                            <img
                              alt="icon"
                              src={qrIconin}
                              style={{
                                width: "12rem",
                                objectFit: "fill",
                                objectPosition: "50% 50%",
                              }}
                            />
                          ) : null}
                        </Avatar>
                      </Box>
                    </Box>
                    <a
                      href="https://www.bdigitall.co"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Button sx={{ margin: "1rem 0rem" }} variant="primary">
                        Redirection vers notre site
                      </Button>
                    </a>
                  </Container>
                </Box>
              )}
          </>
        )
      }
      { 
        // Check for other conditions only if profile is not rendered and no error
        (isProfile === false && role === null && loading === false && !error)
        ? <SelectPlan onSubmit={(data)=> selectRole(data)}/>
        : (role === 'PROSUMER' && (isProfile === false && !error))
        ? <FillProfileProsumer id={data?.id} prosumer={prosumer}/>
        : (role === 'PRO_PLUS_ADMIN' && (isProfile === false))
        ? <FillProfileProPlus id={data?.id} prosumer={prosumer}/>
        : ((role === 'CUSTOMER' || role === 'PRO_PLUS_COLLABORATOR') && (isProfile === false))
        ? <FillProfile id={data?.id} prosumer={prosumer}/>
        : (<></>)
      }
    </>
  );
}

export default AbonneeApercue;
