import { Box, Tab, Tabs, Typography, colors, Button, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { PieChart } from "@mui/x-charts";
import { BarChart } from '@mui/x-charts/BarChart';
import MultiStatsCard from "../../components/ui/MultiStatsCard";
import TotalStatsCard from "../../components/ui/TotalStatsCard";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast,ToastContainer } from "react-toastify";
import API from "../../api/Api_1_3";
import Api from "../../api/Api";
import { useNavigate } from "react-router-dom";
import LineChart from "../../components/ui/LineChart";
import { Urls } from "../../api/Urls";
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from "formik";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import MzErrorText from "../../components/ui/MzErrorText";
import BarChartComponent from "../../components/ui/BarChart";
import FilledFields from "../../components/ui/FilledFields";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const Dashboard = () => {
    const { user } = useAuthContext()
    const access_token = user?.access_token;
    const type = user.user.role
    const id = user.user.id
    const [value, setValue] = useState('one');
    const navigate = useNavigate();
    const [links,setLinks] = useState([]) 
    const [selectedUser, setSelectedUser] = useState('');
    const [stats,setStats] = useState([])
    const [linksBdisplay, setLinksBdisplay] = useState([])
    const [statsBdisplay, setStatsBdisplay] = useState([])
    const [statsBdisplayServices, setStatsBdisplayServices] = useState([])
    const [statsCommon, setStatsCommon] = useState([])
    const [linksCommon, setLinksCommon] = useState([])
    const [PPM, setPerMonth] = useState([])
    const [PPMU,setPerMonthUser] = useState([])
    const [users, setRowsAPI] = useState([]);
    const [data, setFilled] = useState({});
    const [Total,setTotal] = useState(null)
    const [contactsPerPage, setPerPage] = useState([])
    const [ScansPerPage, setScansPerPage] = useState([])
    const [servicePerPage, setServicePerPage] = useState([])
    const [userStats,setUsers] = useState([])
    const [filterDates, setfilterDates] = useState([]);
    const [profileFilling,setFilling] = useState([])
    const [contacts,setContacts] = useState([])
    const [moneyCo2,setMoneyCo2] = useState([])
    const [moneyCo2Bdisplay,setMoneyCo2Bdisplay] = useState([])
    const [contactStats, setContactStats] = useState({
      CLIENT: 0,
      HOT_LEAD: 0,
      CALLED_LEAD: 0,
      LEAD: 0,
      TO_BE_INITIATED: 0
    });

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const initialValuesCartes = {
    start_date: null,
    end_date: null,
    };

    //validation schema
    const validationSchema = Yup.object({
    start_date: Yup.date(),
    end_date: Yup.date(),
    });

    const formRef = useRef();
    const [filter,setFilter] = useState(false)

    const onSubmitSearchUsers = (values) =>{
      if(value.start_date > value.end_date){
        toast.error('Start date must be less than end date');
      }
      else {
       getStats(values)
       getStatsPerMonth(values)
      }
    }

    const handleChangeUser = (event) => {
      console.log(event.target.value);
      setSelectedUser(event.target.value);
      if (event.target.value) {
        const userKey = Object.keys(PPM.user_percentages).find(key => key.startsWith(`${event.target.value} -`));
        if (userKey) {
          setFilter(true)
          setPerMonth({
            ...PPM,
            percenatges_per_month: PPM.user_percentages[userKey],
          });
        }
      }
      else{
        getStatsPerMonth(period)
      }
    };

    const handleApiError = (error) => {
      if (error.response.status === 403) {
          toast.error("Une erreur est survenue");
      } else {
          toast.error(error.response.data.message);
      }
      if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
      }
    };

    const getStats = useCallback((values) => {
    API.get('/report/bcard/scans-report', {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params:{
          start_date: values?.start_date ?? null,
          end_date: values?.end_date ?? null,
        }
      })
        .then((response) => {
          setStats(response.data.data)

        })
        .catch(handleApiError);
    },[])


    const getStatsLinks = useCallback((values) => {
      API.get('/report/bcard/consultations-report', {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params:{
            start_date: values?.start_date ?? null,
            end_date: values?.end_date ?? null,
          }
        })
          .then((response) => {
            setLinks(response.data.data.consultations)
  
          })
          .catch(handleApiError);
    },[])

    // BCARDS KPI - Contacts
    const getContactsReport = useCallback(() => {
    API.get('/report/bcard/contacts-report', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        setContacts(response.data.data);
    })
    .catch(handleApiError);
    }, [])

    // BCARDS KPI - MoneyCo2
    const getMoneyCo2Report = useCallback(() => {
    API.get('/report/bcard/money-carbon-print-report', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
      setMoneyCo2(response.data.data);
    })
    .catch(handleApiError);
    }, [access_token])

  const getStatsBdisplay = (values) => {
    API.get('/report/bdisplay/scans-contacts-report', {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params:{
          start_date: values?.start_date ?? null,
          end_date: values?.end_date ?? null,
        }
      })
        .then((response) => {
          setStatsBdisplay(response.data.data)
        })
        .catch((error) => {
          if (error.response.status === 403) toast.error("Une erreur est survenue");
          else toast.error(error.response.data.message);
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
        });
  }

  const getStatsBdisplayService = () => {
    API.get('/report/bdisplay/services-report', {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          setStatsBdisplayServices(response.data.data.services_consultations_downloads)

        })
        .catch((error) => {
          if (error.response.status === 403) toast.error("Une erreur est survenue");
          else toast.error(error.response.data.message);
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
        });
  }

  const getStatsBdisplayLinks = () => {
    API.get('/report/bdisplay/links-report', {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
            setLinksBdisplay(response.data?.data?.links_consultation)

        })
        .catch((error) => {
          if (error.response.status === 403) toast.error("Une erreur est survenue");
          else toast.error(error.response.data.message);
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
        });
  }

  // BCARDS KPI - MoneyCo2
  const getMoneyCo2ReportBdisplay = useCallback(() => {
    API.get('/report/bdisplay/money-carbon-print-report', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
      setMoneyCo2Bdisplay(response.data.data);
    })
    .catch(handleApiError);
    }, [access_token])

  const getStatsCommon = (values) => {
    API.get('/report/bcard-bdisplay/metrics-summary', {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params:{
          start_date: values?.start_date ?? null,
          end_date: values?.end_date ?? null,
        }
      })
        .then((response) => {
          setStatsCommon(response.data.data)
          setLinksCommon(response.data.data.consultations)

        })
        .catch((error) => {
          if (error.response.status === 403) toast.error("Une erreur est survenue");
          else toast.error(error.response.data.message);
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
        });
  }

  const getContactsPerPage = () =>{
    API.get('/report/bdisplay/contacts-per-bdisplay', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setPerPage(response.data.data)
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }
  
  const getServicesPerPage = () =>{
    API.get('/report/bdisplay/services-consultations-per-bdisplay', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setServicePerPage(response.data.data)
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }

  const getScansPerPage = () =>{
    API.get('/report/bdisplay/scans-per-bdisplay', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setScansPerPage(response.data.data)
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }

  const [monthsDiff,setDiff] = useState(4)
  const getStatsPerMonth = (period) => {
    const now = new Date();
    let startDate = new Date(now);
    let endDate = new Date(now);

    // Adjust the default date range based on the selected period
    switch (period) {
        case 'quad':
            startDate.setMonth(now.getMonth() - 4);
            setDiff(4);
            break;
        case 'semester':
            startDate.setMonth(now.getMonth() - 6);
            setDiff(6);
            break;
        case 'year':
            startDate.setFullYear(now.getFullYear() - 1);
            setDiff(12);
            break;
        default:
            // Default to the last 3 months if no valid period is selected
            startDate.setMonth(now.getMonth() - 4);
            setDiff(4);
            break;
    }

    // Convert to YYYY-MM-DD format for API params
    const startDateStr = startDate.toISOString().split('T')[0];
    const endDateStr = endDate.toISOString().split('T')[0];


    API.get('/report/bcard/scans-percentage', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
        params: {
            start_date: startDateStr,
            end_date: endDateStr,
        }
    })
    .then((response) => {
        setTotal(response.data.data.total_percentage);
        setPerMonth(response.data.data);
        setPerMonthUser(response.data.data.user_percentages)
    })
    .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
        }
    });
  };

  const getColls = () =>{
    Api.get(Urls.GET_COLLABS , {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setRowsAPI(response.data.users);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }

  const getStatsPerUser = () => {
    API.get('/report/bcard/collaborator-contacts', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setUsers(response.data.data);
        if(type === 'COLLABORATOR'){
          setContactStats(response.data?.data?.contacts)
        }
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const getProfileFilled = (values, filters) => {
    const params = {
      start_date: values?.start_date ?? null,
      end_date: values?.end_date ?? null,
    };

    API.get('/report/bcard/profiles-filling-per-user', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params
    })
      .then((response) => {
        setFilled(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const handleChangeUserStats = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);
    const selectedUserData = userStats.find(user => user.collaborator_id === selectedUserId);
    if (selectedUserData) {
      console.log(selectedUserData);
      setContactStats(selectedUserData.contacts);
    }
  };

  const getProfileFilling = (values) => {
    API.get('/report/bcard/filled-profiles-report', {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params:{
          start_date: values?.start_date ?? null,
          end_date: values?.end_date ?? null,
        }
      })
        .then((response) => {
          setFilling(response.data.data)
        })
        .catch((error) => {
          if (error.response.status === 403) toast.error("Une erreur est survenue");
          else toast.error(error.response.data.message);
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
        });
  }

   useEffect(() => {
    if (type === 'CLIENT_ADMIN') {
        getStatsBdisplay();
        getScansPerPage();
        getStatsLinks();
        getContactsReport();
        getServicesPerPage();
        getProfileFilled();
        getStatsCommon();
        setTimeout(() => {
            getMoneyCo2Report();
            getStatsPerMonth(filterDates);
            getStatsPerUser();
            getStatsBdisplayLinks();
            getContactsPerPage();
        }, 1000);
        setTimeout(() => {
            getProfileFilling();
            getStats();
            getColls();
            getMoneyCo2ReportBdisplay();
            getStatsBdisplayService(filterDates);
        }, 2600);
    } else if (type === 'COLLABORATOR') {
        // Only these methods for COLLABORATOR
        getStats();
        getStatsLinks();
        getContactsReport();
        getMoneyCo2Report();
        getStatsPerUser();
        getProfileFilling();
        getProfileFilled();
    }
}, []);

  const dataKeys = [
    { name: 'First Name', value: profileFilling?.filled_columns_percentages?.first_name },
    { name: 'Last Name', value: profileFilling?.filled_columns_percentages?.last_name },
    { name: 'Phone Number', value: profileFilling?.filled_columns_percentages?.phone_number },
    { name: 'Email', value: profileFilling?.filled_columns_percentages?.email },
    { name: 'Department', value: profileFilling?.filled_columns_percentages?.department },
    { name: 'Function', value: profileFilling?.filled_columns_percentages?.function },
    { name: 'Country', value: profileFilling?.filled_columns_percentages?.country_name },
    { name: 'City', value: profileFilling?.filled_columns_percentages?.city_name },
    { name: 'Postal Code', value: profileFilling?.filled_columns_percentages?.postal_code },
    { name: 'Address', value: profileFilling?.filled_columns_percentages?.address },
  ];

  const [period, setPeriod] = useState('quad');
  const handleChangePeriod = (event) => {
    setPeriod(event.target.value);
    getStatsPerMonth(event.target.value)
    
};

const handleGoBack = ()=> {
  navigate(-1)
}


    return ( 
        <Box>
            <ToastContainer
                style={{ top: "5rem", width: "300px" }}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
              <Box>
                <Button 
                  variant="secondary" 
                  startIcon={<ArrowBackIosIcon/>}
                  onClick={() => handleGoBack() }
                  sx={{ marginBottom: "1rem" }}
                >
                  Retour
                </Button>
              </Box>
            <Typography variant="h5" fontWeight={500}>
                Les Dernières mises à jour
            </Typography>
            <Box className="filter" display="flex" mb={2} flexDirection="row" justifyContent={{
              xs:'start',
              sm:'start',
              md:'center',
              lg:'center',
              xl:'center',
            }}>
        <Formik
          innerRef={formRef}
          //initial values
          initialValues={initialValuesCartes}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values, access_token);
            setfilterDates(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column" mt={4}>

                {/*Search fields  */}

                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { md: "column", xs: "column", sm: "column", lg: "row" } }}>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="start_date" as={MyDPFullDate} max={values.start_date} setFieldValue={setFieldValue} variant="outlined" label="Date d'ebut" size="small" />
                    <ErrorMessage name="start_date" component={MzErrorText} />
                  </Box>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="end_date" as={MyDPFullDate} min={values.end_date} setFieldValue={setFieldValue} variant="outlined" label="Date de fin" size="small" />
                    <ErrorMessage name="end_date" component={MzErrorText} />
                </Box>

                  <Box flex={0.5} marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Filtrer
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
            </Box>

            { type === 'CLIENT_ADMIN' && 
                <Box mt={2} mb={3} >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tabs"
              >
                <Tab
                  value="one"
                  label="BCard | BDisplay"
                />

                <Tab
                  value="three"
                  label="BCard"
                />
                <Tab value="two" label="BDisplay" />
              </Tabs>
                </Box>
            }

            {/* B-Card */}
            {value === 'three' &&
            <Box display={'flex'} flexDirection={'column'} gap={6}>
                <section className={`w-full grid grid-cols-2 mt-4 md:grid-cols-2 ${type === 'CLIENT_ADMIN' ? 'lg:grid-cols-2' : 'lg:grid-cols-2'} gap-1`}>
                  <MultiStatsCard total={links?.totalLinks} links={links?.linkPerType ?? {'facebook':0}} title="Nombre de Liens Ouvertes " bgColor="bg-[#5e35b1]" current={'one'}/>
                  <section className={`w-full grid grid-cols-2 md:grid-cols-2 mt-3 place-content-start ${type === 'CLIENT_ADMIN' ? 'lg:grid-cols-2' : 'lg:grid-cols-2'} gap-3`}>
                    <TotalStatsCard total={stats?.scans?.total} type="scan" title="Nombre de Scans Total" bgColor=" bg-[#FFC344]"/>
                    {type === 'CLIENT_ADMIN' && <TotalStatsCard total={stats?.scans?.avg} type="scan" isAvg={true} title="Nombre de Scans Moyenne " bgColor={`bg-[#2AC485]`}/>}
                    <TotalStatsCard total={contacts?.contacts?.total ?? 0} current={'three'} type="contact" title="Nombre de Contacts Total " bgColor=" bg-[#02B2AF]"/>
                    <TotalStatsCard total={links?.totalBrochure} type="brochure" title="Nombre de Brochures Ouvertes " bgColor="bg-[#A96FEB]"/>
                    <TotalStatsCard total={profileFilling?.total_profiles} type="profile" title="Nombre de Profiles Total " bgColor="bg-[#5e35c1]"/>
                    <TotalStatsCard total={profileFilling?.totally_filled_nbr} type="profile" title="Nombre de Profiles Remplis " bgColor="bg-[#5e35a3]"/>
                  </section>
                  <Box display={'flex'} flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>

                    {/* Filled Fields */}
                    <Box textAlign={'center'} width={'100%'}>
                      <Typography fontWeight={600} mt={8} mb={-4}>Pourcentage des champs remplis ({profileFilling.totally_filled_percentage}%) </Typography>
                      <FilledFields data={dataKeys}/>
                    </Box>

                    {/* Collected Contacts */}
                    <Box textAlign={'center'} mt={3} width={'100%'}>
                <Box display={'flex'} flexDirection={'column'} ml={5} >
                <Typography fontWeight={600}>Nombre Total De contacts recoltés </Typography>
                <Typography variant="subtitle2">(par type, par utilisateur)</Typography>
                  <Box width={'100%'} display={'flex'} mt={3} mb={-2} alignItems={'center'} gap={1}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="user-select-label" sx={{fontSize:'16px'}}>Selectionner Un Utilisateur</InputLabel>
                    <Select
                      labelId="user-select-label"
                      value={selectedUser}
                      label="Utilisateur"
                      onChange={handleChangeUserStats}
                      sx={{height:'3rem'}}
                    >
                      {users.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button variant='primary' onClick={()=> {
                setSelectedUser(null)
                getStats()
                  }}>Reset</Button>
                  </Box>
                </Box>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: ['Total De Contacts'] }]}
                        series={[
                            { data: [contactStats.CLIENT], label:'CLIENT', color: colors.blue[600] },
                            { data: [contactStats.HOT_LEAD], label:'HOT LEAD', color: colors.purple[300] },
                            { data: [contactStats.CALLED_LEAD], label:'COLD LEAD', color: colors.purple[200] },
                            { data: [contactStats.LEAD], label:'OPPORTUNITE', color: colors.purple[900] },
                            { data: [contactStats.TO_BE_INITIATED], label:'TO BE INITIATED', color: colors.purple[100] },
                        ]}
                        slotProps={{
                          legend:{
                            hidden:true
                          }
                        }}
                        width={500}
                        height={250}
                        dataKey="name"
                    />
                    </Box>

                    
                  </Box>
                </section>
                
                {/* Pies */}
                <Box display={'flex'} alignItems={'center'} textAlign={'center'} justifyContent={'center'} flexDirection={{
                  xs:'column',
                  sm:'column',
                  md:'row',
                  lg:'row',
                  xl:'row',
                }}>

                  <Box flexGrow={1}>
                    <PieChart
                    series={[
                        {
                            data: [
                              { id: 0, value: moneyCo2?.moneySaved?.scans, label: 'Scans' },
                              { id: 1, value: moneyCo2?.moneySaved?.brochures, label: 'Brochures' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    width={500}
                    height={250}
                    />
                    <Typography fontWeight={600}>Argent économisé (DZD)</Typography>
                  </Box>

                  <Box flexGrow={1}>
                    <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: moneyCo2?.carbonPrintSaved?.scans, label: 'Scans' },
                                { id: 1, value: moneyCo2?.carbonPrintSaved?.brochures, label: 'Brochures' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    width={500}
                    height={250}
                    />
                    <Typography fontWeight={600}>CO2 print économisé (Kg CO2eq/kg de papier)</Typography>
                  </Box>
                </Box>

                {/* BarChart */}
                <BarChartComponent values={filterDates} users={users}/>

                {/* Scan percentage */}
                <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>

                {type === 'CLIENT_ADMIN' &&
                  <Box display={'flex'} gap={3}>
                    {/* user list */}
                    { monthsDiff > 3 && 
                      <FormControl fullWidth>
                      <InputLabel id="user-select-label">Selectionner Un Utilisateur</InputLabel>
                      <Select
                        labelId="user-select-label"
                        value={type === 'COLLABORATOR' ? id : selectedUser}
                        label="Utilisateur"
                        onChange={handleChangeUser}
                      >
                        <MenuItem defaultChecked defaultValue={true} value={''}>Selectionner Un Utilisateur</MenuItem>
                        {users.map(user => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.first_name} {user.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                      </FormControl>
                    }

                    {/* time interval */}
                  <FormControl fullWidth>
                  <InputLabel id="time-period-select-label">Time Period</InputLabel>
                  <Select
                      labelId="time-period-select-label"
                      value={period}
                      onChange={handleChangePeriod}
                      label="Time Period"
                  >
                      <MenuItem value="quad">Dernière Quadrimestre</MenuItem>
                      <MenuItem value="semester">Dernière Semestre</MenuItem>
                      <MenuItem value="year">Dernière année</MenuItem>
                  </Select>
                  </FormControl>
                  </Box>
                }

                {/* Graphs */}
                <Box display={'flex'} mt={4} width={'100%'} justifyContent={'start'}>

                   {/* Graph 1 */}

                  <Box display={'flex'} flexDirection={'column'} width={'100%'} alignItems={'center'} gap={2}>
                    <LineChart data={PPM} isShortDuration={monthsDiff} filter={filter}/>
                    <Typography fontWeight={600} mb={4} textAlign={'center'}>
                      Pourcentage {monthsDiff > 3 ? `Total: ${Total ?? 0}%` : `Par Mois (Total: ${Total ?? 0}%)`}
                    </Typography>
                  </Box>

                </Box>
 
                </Box>

            </Box>
            }

            {/* Bdisplay */}
            {value === 'two' &&
            <Box display={'flex'} flexDirection={'column'} gap={6}>
              <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
                <MultiStatsCard total={linksBdisplay?.total} links={linksBdisplay?.per_type ?? {'facebook':0}} title="Nombre de Liens Ouvertes " bgColor="bg-[#893AE4]" current={'two'}/>
                <section className={`w-full grid grid-cols-2 md:grid-cols-2 mt-6 place-content-start ${type === 'CLIENT_ADMIN' ? 'lg:grid-cols-2' : 'lg:grid-cols-2'} gap-3`}>
                    <TotalStatsCard total={statsBdisplay?.scans} type="scan" title="Nombre de Scans Total" bgColor=" bg-[#FFC344]"/>
                    <TotalStatsCard total={statsBdisplay?.contacts} type="Contact" title="Nombre de Contacts Total " bgColor=" bg-[#2AC485]"/>
                    <TotalStatsCard total={23} type="scan" title="Nombre de Scans Moyenne " bgColor={`bg-[#02B2AF]`}/>
                    <TotalStatsCard total={20} type="Brochure" title="Nombre de Brochures Ouvertes " bgColor="bg-[#A96FEB]"/>

                    <section className="w-full flex flex-row col-start-[span_2]">

                      <Box flexGrow={1} width={'100%'}>
               <PieChart
               slotProps={{
                legend:{
                    hidden:true
                }
               }}
               series={[
                   {
                        data: ScansPerPage.map((cnt) => ({
                          id: cnt.page_id,
                          value: cnt.scans,
                          label: cnt.page_name,
                        })),
                       highlightScope: { faded: 'global', highlighted: 'item' },
                       faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                       outerRadius: 103,
                   },
               ]}
                height={250}
                width={300}
               />
               <Typography fontSize={'.8rem'} fontWeight={600}>Nombre de Scans (par Bdisplay)</Typography>
                      </Box>

                      <Box flexGrow={1} width={'100%'}>
               <PieChart
               slotProps={{
                legend:{
                    hidden:true
                }
               }}
               series={[
                   {
                        data: contactsPerPage.map((cnt) => ({
                          id: cnt.page_id,
                          value: cnt.contacts,
                          label: cnt.page_name,
                        })),
                       highlightScope: { faded: 'global', highlighted: 'item' },
                       faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                       outerRadius: 103,
                   },
               ]}
               height={250}
               width={300}
               />
               <Typography fontSize={'.8rem'} fontWeight={600}>Nombre de Contacts (par Bdisplay)</Typography>
                      </Box>
           
                    </section>

                    {/* money saved */}
                    <Box flexGrow={1} width={'100%'}>
               <PieChart
               slotProps={{
                legend:{
                    hidden:true
                }
               }}
               series={[
                   {
                    data: [
                      { id: 0, value: moneyCo2Bdisplay?.moneySaved?.scans, label: 'Scans', color:'#FFC344' },
                      { id: 1, value: moneyCo2Bdisplay?.moneySaved?.brochures, label: 'Brochures' },
                  ],
                       highlightScope: { faded: 'global', highlighted: 'item' },
                       faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                       outerRadius: 103,
                   },
               ]}
                height={250}
                width={300}
               />
               <Typography fontSize={'.8rem'} fontWeight={600}>Argent économisé (par Bdisplay)</Typography>
                    </Box>

                    {/* carbon print saved */}
                    <Box flexGrow={1} width={'100%'}>
               <PieChart
               slotProps={{
                legend:{
                    hidden:true
                }
               }}
               series={[
                   {
                    data: [
                      { id: 0, value: moneyCo2Bdisplay?.carbonPrintSaved?.scans, label: 'Scans', color:'#FFC344' },
                      { id: 1, value: moneyCo2Bdisplay?.carbonPrintSaved?.brochures, label: 'Brochures'},
                  ],
                       highlightScope: { faded: 'global', highlighted: 'item' },
                       faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                       outerRadius: 103,
                   },
               ]}
                height={250}
                width={300}
               />
               <Typography fontSize={'.8rem'} fontWeight={600}>CO2 print économisé (par Bdisplay)</Typography>
                    </Box>
                </section>

                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4} mt={5} justifyContent={'center'}>
                  <Box flexGrow={1} textAlign="center" width={'100%'}>
            <BarChart
                xAxis={[{ scaleType: 'band',data:[''] }]}
                series={[
                    { data: [statsBdisplayServices?.per_type.CONSULT_URL],label:'Consultations', color: colors.blue[600] },
                    { data: [statsBdisplayServices?.per_type.DOWNLOAD_BROCHURE],label:'Téléchargements', color: colors.purple[900] },
                ]}
                width={400}
                height={300}
                dataKey="name"
            />
             <Typography variant="subtitle1" fontWeight={600} mt={-3}>Total Consultations, Téléchargements ({statsBdisplayServices?.total}) </Typography>
                  </Box>

                  <Box flexGrow={1} textAlign={'center'} width={'100%'}>
            <BarChart
              xAxis={[{ scaleType: 'band', data: servicePerPage.map((srv) => srv.page_name) }]}
              series={[
                {
                  data: servicePerPage.map((srv) => srv.services_consultations.CONSULT_URL),
                  label: 'URL',
                },
                {
                  data: servicePerPage.map((srv) => srv.services_consultations.DOWNLOAD_BROCHURE),
                  label: 'Brochure',
                },
              ]}
              width={500}
              height={300}
              dataKey="name"
            />

                <Typography fontWeight={600}>Nombre Total De Consultation Du Services (par type) </Typography>
                  </Box>
                </Box>
              </section>


            </Box>
            }

            {/* B-Card / Bdisplay */}
            {value === 'one' &&
            <Box display={'flex'} flexDirection={'column'} gap={6}>
              <section className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3">
                <MultiStatsCard total={linksCommon?.totalLinks ?? links?.totalLinks} links={(linksCommon?.linkPerType || links?.linkPerType) ?? {'facebook':0}} title="Nombre de Liens Ouvertes" bgColor="bg-[#5e35b1]" current={'three'}/>
                <section className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3">
                    <TotalStatsCard total={statsCommon.scans?.total ?? stats.scans?.total} type="scan" title="Nombre de Scans Total" bgColor=" bg-[#2AC485]" current={'three'}/>
                    <TotalStatsCard total={statsCommon.contacts?.total ?? contacts.contacts?.total} type="contact" title="Nombre de Contacts Totale " bgColor=" bg-[#02B2AF]" current={'three'}/>
                    <TotalStatsCard total={linksCommon?.totalBrochure ?? links?.totalBrochure} type="brochure" title="Nombre de Brochures Téléchargés " bgColor="bg-[#A96FEB]" current={'three'}/>
                </section>

                <Box display={'flex'} flexGrow={2} flexDirection={'column'} textAlign="start" width={'100%'}>
                  <Typography fontWeight={600} mt={2} ml={4} mb={-2}>Nombre Total De contacts (par type) </Typography>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: ['Total De contacts'] }]}
                        series={[
                            { data: [ type === 'COLLABORATOR' ? contacts.contacts?.perType['CLIENT'] : statsCommon.contacts?.perType['CLIENT']], label:'CLIENT', color: colors.blue[600] },
                            { data: [ type === 'COLLABORATOR' ? contacts.contacts?.perType['HOT_LEAD'] : statsCommon.contacts?.perType['HOT_LEAD']], label:'HOT LEAD', color: colors.purple[300] },
                            { data: [ type === 'COLLABORATOR' ? contacts.contacts?.perType['CALLED_LEAD'] : statsCommon.contacts?.perType['CALLED_LEAD']], label:'COLD LEAD', color: colors.purple[200] },
                            { data: [ type === 'COLLABORATOR' ? contacts.contacts?.perType['LEAD'] : statsCommon.contacts?.perType['LEAD']], label:'OPPORTUNITE', color: colors.purple[900] },
                            { data: [ type === 'COLLABORATOR' ? contacts.contacts?.perType['TO_BE_INITIATED'] : statsCommon.contacts?.perType['TO_BE_INITIATED']], label:'TO BE INITIATED', color: colors.purple[100] },
                        ]}
                        slotProps={{
                          legend:{
                            hidden:true
                          }
                        }}
                        width={700}
                        height={300}
                        dataKey="name"
                    />

                    <Box display={'flex'} flexDirection={'row'} ml={4}>
                      <Box width={'100%'}>
                    <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: type === 'COLLABORATOR' ? moneyCo2?.moneySaved?.scans : statsCommon?.moneySaved?.scans, label: 'Scans', color:'#FFC344' },
                                { id: 1, value: type === 'COLLABORATOR' ? moneyCo2?.moneySaved?.brochures : statsCommon?.moneySaved?.brochures, label: 'Brochures' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    height={250}
                    width={300}
                    slotProps={{
                      legend:{
                        hidden:true
                      }
                    }}
                    />
                    <Typography fontWeight={600}>Argent économisé (DZD)</Typography>
                      </Box>

                      <Box width={'100%'}>
                    <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: type === 'COLLABORATOR' ? moneyCo2?.carbonPrintSaved?.scans : statsCommon?.carbonPrintSaved?.scans, label: 'Scans', color:'#FFC344' },
                                { id: 1, value: type === 'COLLABORATOR' ? moneyCo2?.carbonPrintSaved?.brochures : statsCommon?.carbonPrintSaved?.brochures, label: 'Brochures' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    height={250}
                    width={370}
                    />
                    <Typography fontWeight={600} textAlign={'center'}>CO2 print économisé (Kg CO2eq/kg de papier)</Typography>
                      </Box>
                    </Box>
                </Box>

              </section>

                {type === 'COLLABORATOR' &&
                  <Box flexGrow={1} mt={8} textAlign={'center'} width={'100%'}>
                  <BarChart
                      xAxis={[{ scaleType: 'band', data: ['Pourcentage Du Profile rempli (%)'] }]}
                      tooltip={'line'}
                      series={[
                          { data: [profileFilling?.filled_columns_percentages?.first_name], label:'First Name', color: colors.blue[600], },
                          { data: [profileFilling?.filled_columns_percentages?.last_name], label:'Last Name', color: colors.purple[900] },
                          { data: [profileFilling?.filled_columns_percentages?.phone_number], label:'Phone Number', color: colors.purple[300] },
                          { data: [profileFilling?.filled_columns_percentages?.email], label:'Email', color: colors.purple[200] },
                          { data: [profileFilling?.filled_columns_percentages?.department], label:'Department', color: colors.purple[100] },
                          { data: [profileFilling?.filled_columns_percentages?.function], label:'Function', color: '#32cbff' },
                          { data: [profileFilling?.filled_columns_percentages?.country_name], label:'Country', color: '#00a5e0' },
                          { data: [profileFilling?.filled_columns_percentages?.city_name], label:'City', color: '#89a1ef' },
                          { data: [profileFilling?.filled_columns_percentages?.postal_code], label:'Postal Code', color: '#e27396' },
                          { data: [profileFilling?.filled_columns_percentages?.address], label:'Address', color: '#ffc2d1' },
                      ]}
                      slotProps={{
                        legend:{
                          hidden:true
                        }
                      }}
                      width={1000}
                      height={300}
                      dataKey="name"
                  />
                <Typography fontWeight={600}>Pourcentage Du Profile rempli ({profileFilling.totally_filled_percentage}%) </Typography>
                </Box>
                }

            </Box>
            }

        </Box>
     );
}
 
export default Dashboard;