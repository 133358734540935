import { colors } from "../../theme/Colors";
import { Box, Typography, Button } from '@mui/material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { ArrowBack } from '@mui/icons-material';
import bdigitall from "../../imgs/BDIGITALL.svg";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PinField from 'react-pin-field';
import API from "../../api/Api_1_3";
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';

const SelectPlan = ({onSubmit}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [pin, setPin] = useState(''); // State to track pin input
    const [isPinInputVisible, setIsPinInputVisible] = useState(false); 
    const [error,setError] = useState(false)
    const [data,setData] = useState(null)


    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handlePlanSelect = ()=> {
        if (selectedOption === 'customer') {
            onSubmit(); // Call onSubmit for customer option
        } else if (selectedOption === 'prosumer' || selectedOption === 'pro+') {
            setIsPinInputVisible(true); // Show pin input for prosumer option
        }
    }

    const setUsertype = () =>{
        switch(selectedOption){
            case 'prosumer':
                return 'PROSUMER'
            case 'pro+':
                return 'PRO_PLUS_ADMIN'
            default:
                return 'PRO_PLUS_COLLABORATOR'
        }
    }

    const validatePin = async () => {
        try {
            const response = await API.post('/check-individual-passcode',{
                pass_code: pin,
                user_type_selected: setUsertype()
            }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                onSubmit(response.data.data);
                setError(false)
            }
        } catch (error) {
            setError(true)
            toast.error(error.response.data.message); // Handle fetch error
        }
    };

    useEffect(()=>{
        handlePlanSelect()
    },[selectedOption])

    return ( 
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent='space-between' gap={7} mt={3} sx={{width: { lg: '80%', xs: '90%' }}} >
            {/* Header */}
            <Box>
                <Box
                      gap="0.5rem"
                      width="100%"
                      display="flex"
                      sx={{
                        alignItems: "center",
                        flexDirection: {
                          sm: "column-reverse",
                          md: "column-reverse",
                          lg: "row",
                          xs: "column-reverse",
                        },
                        justifyContent: {
                          sm: "center",
                          md: "center",
                          xs: "center",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: {
                            xs: "1rem",
                            sm: "1rem",
                            md: "1rem",
                            lg: "0",
                          },
                          marginBottom:'1rem',
                        }}
                      >
                        <img 
                          alt="icon"
                          src={bdigitall}
                          style={{
                            width: {
                              xs: '10px',
                              sm: '10px',
                              md: '30px',
                              lg: '30px',
                              xl: '30px'
                            }
                          }}
                        />
                      </Box>
                </Box>
            </Box>

            {!isPinInputVisible &&
            (<>
                <Typography variant='h5' mb={-3} fontSize={'28px'} fontWeight={500} textAlign={'center'} mt={-2}>Welcome to Bdigitall</Typography>

                {/* description */}
                <Box display={'flex'} flexDirection='column' alignItems='center' gap={'8px'} mb={-2} justifyContent='center'>
                <Typography variant='body2' fontSize={'20px'} fontWeight={500} color={colors.carbon_black} textAlign={'center'}>Select the Perfect Plan for You</Typography>
                <Typography variant='body2' fontSize={'17px'} fontWeight={400} color={colors.gray} textAlign={'center'}>Select a plan that works best for you and your goals.</Typography>
                </Box>

                {/* Options */}
                <Box display={'flex'} justifyContent='center' gap={1.5} flexWrap={'wrap'}>

                {/* Customer Option */}
                <Box 
                    display='flex' 
                    flexDirection='column' 
                    alignItems='center'
                    cursor='pointer'
                    py={3}
                    px={2.5}
                    onClick={() => handleOptionSelect('customer')} // Update to use handleOptionSelect
                    sx={{ 
                        border: '2px solid' + (selectedOption === 'customer' ? colors.light_purple : colors.gray),
                        borderRadius:'16px',
                        cursor: 'pointer', 
                        '&:hover': { borderColor: colors.light_purple },
                        '&.selected': { borderColor: colors.light_purple },
                        // Change icon and text color based on selection
                        color: selectedOption === 'customer' ? colors.light_purple : 'inherit',
                    }}
                >
                    <PersonOutlineIcon sx={{ color: selectedOption === 'customer' ? colors.light_purple : colors.gray, fontSize: '80px' }}  />
                    <Typography variant='body2' fontWeight={500} fontSize={'18px'} color={(selectedOption === 'customer' ? colors.light_purple : colors.gray)}>Lite</Typography>
                </Box>
                {/* Prosumer Option */}
                <Box 
                    display='flex' 
                    flexDirection='column' 
                    alignItems='center'
                    cursor='pointer'
                    py={3}
                    px={2.5} 
                    onClick={() => handleOptionSelect('prosumer')} // Update to use handleOptionSelect
                    sx={{ 
                        border: '2px solid' + (selectedOption === 'prosumer' ? colors.light_purple : colors.gray),
                        borderRadius:'16px',
                        cursor: 'pointer', 
                        '&:hover': { borderColor: colors.light_purple },
                        '&.selected': { borderColor: colors.light_purple },
                        // Change icon and text color based on selection
                        color: selectedOption === 'prosumer' ? colors.light_purple : 'inherit',
                        position: 'relative', // Added for absolute positioning of the lock icon
                    }}
                >
                    <Box display='flex' alignItems='center'>
                        <PersonOutlineIcon sx={{ color: selectedOption === 'prosumer' ? colors.light_purple : colors.gray,fontSize: '80px' }} />
                        {/* Lock Icon */}
                        <Box 
                            sx={{
                                position: 'absolute',
                                bottom: '52px', 
                                right: '12px',
                            }}
                        >
                            <LockIcon sx={{ color: selectedOption === 'prosumer' ? colors.light_purple : colors.gray, fontSize: '38px' }} /> {/* Add your lock icon here */}
                        </Box>
                    </Box>
                    <Typography variant='body2' fontWeight={500} fontSize={'18px'} color={(selectedOption === 'prosumer' ? colors.light_purple : colors.gray)}>Pro</Typography>
                </Box>

                {/* Pro+ Option */}
                <Box 
                    display='flex' 
                    flexDirection='column' 
                    alignItems='center'
                    cursor='pointer'
                    py={3}
                    px={2.5} 
                    onClick={() => handleOptionSelect('pro+')} // Update to use handleOptionSelect
                    sx={{ 
                        border: '2px solid' + (selectedOption === 'pro+' ? colors.light_purple : colors.gray),
                        borderRadius:'16px',
                        cursor: 'pointer', 
                        '&:hover': { borderColor: colors.light_purple },
                        '&.selected': { borderColor: colors.light_purple },
                        // Change icon and text color based on selection
                        color: selectedOption === 'pro+' ? colors.light_purple : 'inherit',
                        position: 'relative', // Added for absolute positioning of the lock icon
                    }}
                >
                    <Box display='flex' alignItems='center'>
                        <PersonOutlineIcon sx={{ color: selectedOption === 'pro+' ? colors.light_purple : colors.gray,fontSize: '80px' }} />
                        {/* Lock Icon */}
                        <Box 
                            sx={{
                                position: 'absolute',
                                bottom: '52px', 
                                right: '12px',
                            }}
                        >
                            <LockIcon sx={{ color: selectedOption === 'pro+' ? colors.light_purple : colors.gray, fontSize: '38px' }} /> {/* Add your lock icon here */}
                        </Box>
                    </Box>
                    <Typography variant='body2' fontWeight={500} fontSize={'18px'} color={(selectedOption === 'pro+' ? colors.light_purple : colors.gray)}>Teams</Typography>
                </Box>

                </Box>
            </>)
            }


            {isPinInputVisible && ( 
                <>
                    <Box display={'flex'} sx={{cursor:'pointer'}} alignSelf={'flex-start'} alignItems={'center'} gap={1} onClick={()=> setIsPinInputVisible(false)}>
                      <ArrowBack/>
                    </Box>
                    {/* description */}
                    <Box display={'flex'} flexDirection='column' alignItems='center' gap={'8px'} justifyContent='center'>
                        <Typography variant='body2' fontSize={'20px'} fontWeight={500} color={colors.carbon_black} alignSelf={'center'}>Enter your PIN code</Typography>
                        <Typography variant='body2' fontSize={'17px'} fontWeight={400} color={colors.gray} textAlign={'center'}>We’ve sent a 6 digit code to your email address.</Typography>
                    </Box>
                    <Box display="flex" flexDirection={'column'} p={3} sx={{border: '1px solid' + colors.gray, borderRadius:'8px'}} justifyContent="center" alignItems={'center'} gap={3}>
                        <Box>
                        <PinField 
                            length={6} 
                            onChange={(value) => setPin(value)} // Update pin state
                            style={{
                                width: '40px', // Width of each pin input
                                height: '40px', // Height of each pin input
                                margin: '0 5px', // Margin between inputs
                                border: '1px solid', // Border style
                                borderColor: error ? colors.mj_red : colors.gray, // Default border color
                                borderRadius: '16px', // Rounded corners
                                fontSize: '24px', // Font size for the pin
                                textAlign: 'center', // Center text
                                outline: 'none', // Remove outline on focus
                                transition: 'border-color 0.3s', // Smooth transition for border color
                            }}
                            inputStyle={{
                                border: 'none', // Remove default input border
                                width: '100%', // Full width of the container
                                height: '100%', // Full height of the container
                                fontSize: '24px', // Font size for the pin
                                textAlign: 'center', // Center text
                            }}
                            onFocus={(e) => e.target.style.borderColor = colors.light_purple} // Change border color on focus
                            onBlur={(e) => e.target.style.borderColor = colors.gray} // Reset border color on blur
                        />
                        </Box>
                        <Button variant="form" sx={{width:'60%',fontSize:18}} onClick={validatePin}>
                            Envoyer
                        </Button>
                    </Box>

                </>
            )}
        </Box>
        </Box>
     );
}
 
export default SelectPlan;
